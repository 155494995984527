import React from 'react';

import Logo from '../icons/Logo';

const renderIcons = (icon) => {
	if (!icon) return null;

	switch (icon) {
	case 'logo':
		return <Logo />;
	default:
		return null;
	}
};

export default renderIcons;
