import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import '../scss/main.scss';

import Header from '../components/Header';
import Footer from '../components/Footer';

const nsBase = 'layout';
const ns = `${ nsBase }-default`;

const DefaultLayout = ({ children, location }) => {
	const data = useStaticQuery(graphql`
    query GET_GLOBALS {
			globalJson {
				header {
					links {
						label
						url
					}
				}
				footer {
					items {
						heading,
						description,
						address {
							text,
							url
						},
						email
					}
				}
			}
	  }
  `);

	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true
	});

	return (
		<div className={rootClassnames}>
			<Helmet>
				<link rel={'stylesheet'} type={'text/css'} href={'https://use.typekit.net/xwx4lkn.css'} />
			</Helmet>
			<div className={`${ ns }__header`}>
				<Header header={data.globalJson.header} pathname={location.pathname} />
			</div>
			<div className={`${ ns }__content`}>
				{children}
			</div>
			<div className={`${ ns }__footer`}>
				<Footer footer={data.globalJson.footer} />
			</div>
		</div>
	);
};

export default DefaultLayout;
