/* Page Transition Functions */

import { TimelineMax } from 'gsap';

export const fadeAnimationExit = () => {
	if (window.scrollY > 50) {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}
	const body = document.getElementsByTagName('body')[0];
	const { pathname } = window.location;

	if (pathname !== '/') {
		body.setAttribute('data-page', '');
	}

	// node.style.opacity = 0;
	// node.style.transition = 'opacity 5s ease-in-out';

	// content
	const heroHeading = document.querySelector('.component-hero__heading');
	const heroContent = document.querySelector('.component-hero__content');

	return new TimelineMax()
		.set(body, {
			overflow: 'hidden'
		})
		.to([heroHeading, heroContent], 0.75, {
			opacity: 0,
			ease: 'ease'
		});
};

export const fadeAnimationEntry = () => {
	if (window.location.pathname === '/') {
		document.querySelector('.page-home').classList.add('loaded');
	}

	const body = document.getElementsByTagName('body')[0];

	// content
	// const content = document.querySelector('.layout-default__content');

	return new TimelineMax()
		// .to(content, 0.25, {
		// 	opacity: 1,
		// 	ease: 'ease',
		// 	clearProps: 'all'
		.set(body, {
			overflow: 'auto'
		});
};
