import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import uniqueId from 'uniqid';

import Logo from '../Logo';
import Link from '../Link';

const nsBase = 'component';
const ns = `${ nsBase }-footer`;

/**
 * Get the current year
 *
 * @returns String
 */
function getCurrentYear () {
	return new Date().getFullYear().toString();
}

/**
 * Replace dashes with dots
 *
 * @param {*} str
 * @returns String
 */
function formatPhoneText (str) {
	return str.replace(/-/gi, '.');
}

const Footer = ({ footer }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<Logo variant={`white`} />
				<ul className={`${ ns }__columns`}>
					{footer.items.map((item) => {
						const {
							heading,
							description,
							address: {
								url: addressUrl,
								text: addressText
							},
							email,
							phone
						} = item;

						return (
							<li key={uniqueId('envoy')} className={`${ ns }__column`}>
								{heading && (
									<h5>{heading}</h5>
								)}
								{description && (
									<p>{description}</p>
								)}
								{addressUrl && addressText && (
									<p>
										<Link href={addressUrl} target={'_blank'}>
											{parse(addressText)}
										</Link>
									</p>
								)}
								{email && (
									<p>
										<Link href={`mailto:${ email }`}>
											{email}
										</Link>
									</p>
								)}
								{phone && (
									<p>
										<Link href={`tel:${ phone }`}>
											{formatPhoneText(phone)}
										</Link>
									</p>
								)}
							</li>
						);
					})}
				</ul>
				<p>
					<small>© </small>
					<span>Envoy </span>
					<span>{getCurrentYear()}</span>
				</p>
			</div>
		</div>
	);
};

export default Footer;
