import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { TweenMax } from 'gsap';

import Logo from '../Logo';
import Link from '../Link';

const nsBase = 'component';
const ns = `${ nsBase }-header`;

const Header = ({ header, pathname }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [menuItemHovered, setMenuItemHovered] = useState(false);
	const [goingUp, setGoingUp] = useState(false);
	const [isSticky, setIsSticky] = useState(false);
	const prevScrollY = useRef(0);
	const menu = useRef(null);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--sticky`]: isSticky,
		[`menu-open`]: menuOpen,
		[`menu-item-hovered`]: menuItemHovered
	});

	const handleMenu = () => {
		if (window.innerWidth < 768) {
			setMenuOpen(!menuOpen);
			setIsSticky(false);
		}
	};

	const handleMenuItemHover = (e, menuHovered) => {
		setMenuItemHovered(menuHovered);
		e.currentTarget.style.opacity = menuHovered ? 1 : '';
	};

	useEffect(() => {
		const menuDropdown = menu.current;
		const navList = menuDropdown.querySelectorAll('.component-header__menu-item a');
		const { body } = document;

		if (menuOpen) {
			TweenMax.set(navList, {
				transformStyle: 'preserve-3d',
				autoRound: false,
				rotation: 0.01,
				force3D: true,
				opacity: 0,
				transition: 'opacity 0s',
				transform: 'translateY(-50px)'
			});

			// disable body scroll
			body.style.overflow = 'hidden';

			// reveal nav links
			TweenMax.staggerTo(navList, 0.55, { // duration of the animation
				opacity: 1,
				transform: 'translateY(0)',
				delay: 0.65 // delays the animation start
			}, 0.1); // delay between stagger
		} else {
			if (window.innerWidth < 768) {
				TweenMax.staggerTo(navList, 0.55, { // duration of the animation
					opacity: 0,
					transform: 'translateY(-50px)',
					delay: 0.65 // delays the animation start
				}, 0.1); // delay between stagger
			}

			// enable body scroll
			body.style.overflow = 'auto';
		}
	}, [menuOpen]);

	useEffect(() => {
		const handleStickyHeader = (scrollY) => {
			if ((scrollY >= 250) && goingUp) {
				return setIsSticky(true);
			}

			return setIsSticky(false);
		};

		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			if (prevScrollY.current < currentScrollY && goingUp) {
				setGoingUp(false);
			}
			if (prevScrollY.current > currentScrollY && !goingUp) {
				setGoingUp(true);
			}

			prevScrollY.current = currentScrollY;
			handleStickyHeader(currentScrollY);
		};

		const handleResize = () => {
			if (window.innerWidth < 768) {
				setMenuOpen(false);
			}
		};

		window.addEventListener('scroll', handleScroll, { passive: true });
		window.addEventListener('resize', handleResize, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleResize);
		};
	}, [goingUp]);

	return (
		<div className={rootClassnames}>
			{/* main menu */}
			<div className={`${ ns }__menu-bar`}>
				<div className={'container-fluid'}>
					<div className={`${ ns }__container`}>
						<div className={`${ ns }__logo`}>
							<Logo
								variant={`white`}
								onClick={() => { handleMenu(); }}
							/>
						</div>
						<div className={`${ ns }__headline`}>
							Envoy Ventures
							<br />
							Investing in Experiences
						</div>
						<button
							type={'button'}
							aria-label={'Main nav menu open'}
							className={`${ ns }__menu-toggle`}
							onClick={() => { handleMenu(); }}
						>
							<div />
							<div />
						</button>
						<div className={`${ ns }__menu`} ref={menu}>
							{header.links.map((link) => {
								const activeLinkClass = pathname.includes(link.label.toLowerCase()) ? 'active' : '';
								return (
									<div
										key={link.label}
										className={`${ ns }__menu-item ${ activeLinkClass }`}
										data-to-page={link.label}
										onMouseEnter={(e) => { handleMenuItemHover(e, true); }}
										onMouseLeave={(e) => { handleMenuItemHover(e, false); }}
									>
										<Link
											to={link.url}
											variant={'underline'}
											onClick={() => { handleMenu(); }}
										>
											{link.label}
										</Link>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			{/* sticky menu */}
			<div className={`${ ns }__menu-bar-sticky`}>
				<div className={`${ ns }__bg`} />
				<div className={'container-fluid'}>
					<div className={`${ ns }__container`}>
						<div className={`${ ns }__logo`}>
							<Logo variant={`black`} />
						</div>
						<div className={`${ ns }__headline`}>
							Envoy Ventures
						</div>
						<button
							type={'button'}
							aria-label={'Sticky nav menu open'}
							className={`${ ns }__menu-toggle`}
							onClick={() => { handleMenu(); }}
						>
							<div />
							<div />
						</button>
						<div className={`${ ns }__menu-sticky`}>
							{header.links.map((link) => {
								const activeLinkClass = pathname.includes(link.label.toLowerCase()) ? 'active' : '';
								return (
									<div
										key={link.label}
										className={`${ ns }__menu-item ${ activeLinkClass }`}
										data-to-page={link.label}
										onMouseEnter={(e) => { handleMenuItemHover(e, true); }}
										onMouseLeave={(e) => { handleMenuItemHover(e, false); }}
									>
										<Link
											to={link.url}
											variant={'underline'}
										>
											{link.label}
										</Link>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
