import React from 'react';
import classNames from 'classnames';

import renderIcon from '../../functions/renderIcon';

import Link from '../Link';

const nsBase = 'component';

const ns = `${ nsBase }-logo`;

const Logo = (props) => {
	const {
		variant,
		href,
		handleMenu
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ variant }`]: true
	});

	const handleLogoClick = () => {
		if (handleMenu) {
			handleMenu();
		}
	};

	return (
		<div className={rootClassnames}>
			<Link
				className={'no-hover'}
				to={href}
				variant={variant}
				aria-label={`Logo`}
				onClick={() => { handleLogoClick(); }}
			>
				{renderIcon('logo')}
			</Link>
		</div>
	);
};

Logo.defaultProps = {
	variant: 'default',
	href: '/'
};

export default Logo;
