import React from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import TransitionLink from 'gatsby-plugin-transition-link';

import SafeAnchor from '../SafeAnchor';

import { isExternalUrl } from '../../functions';
import { fadeAnimationExit, fadeAnimationEntry } from '../../functions/page-transitions';

/*
- to prop will create a 'gatsby' link
- href prop wll create a regular a-tag
- disabled prop will add 'tabindex' of -1 and 'aria-disabled' of 'true' for accessibility
- variant prop will add a modifier to classname
*/

const nsBase = 'component';
const ns = `${ nsBase }-link`;

const Link = React.forwardRef(
	(
		{
			className,
			disabled,
			variant,
			to,
			href,
			fade,
			customFade,
			...props
		},
		ref
	) => {
		// define link classes
		const rootClassnames = classNames(
			className,
			`${ ns }`,
			`${ ns }--${ variant }`
		);

		/* eslint-disable */
		if (href || isExternalUrl(to)) {
			return (
				<SafeAnchor
					{...props}
					disabled={disabled}
					href={href || to}
					ref={ref}
					className={classNames(rootClassnames)}
				/>
			);
		}

		if (disabled) {
			props.tabIndex = -1;
			props['aria-disabled'] = true;
		}

		if (customFade) {
			return (
				<TransitionLink
					{...props}
					disabled={disabled}
					exit={{
						length: 2.25,
						trigger: ({ exit, node }) => {
							fadeAnimationExit(node);
						},
						state: { test: 'exit state' },
					}}
					entry={{
						delay: 0.75,
						trigger: () => {
							fadeAnimationEntry();
						}
					}}
					to={to || '/'}
					ref={ref}
					className={rootClassnames}
				>
					{props.children}
				</TransitionLink>
			);
		}

		if (fade) {
			return (
				<AniLink
					fade
					{...props}
					disabled={disabled}
					to={to || '/'}
					ref={ref}
					className={rootClassnames}
				/>
			)
		}

		return (
			<GatsbyLink
				{...props}
				disabled={disabled}
				to={to || '/'}
				ref={ref}
				className={rootClassnames}
			/>
		);
		/* eslint-enable */
	}
);

Link.defaultProps = {
	variant: 'default',
	disabled: false,
	fade: true,
	customFade: true
};

export default Link;
