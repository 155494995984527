// import './src/scss/main.scss';

exports.onInitialClientRender = () => {
	// console.log('ReactDOM.render has executed');
	const body = document.getElementsByTagName('body')[0];
	const homePage = document.querySelector('.page-home');
	// const headerMenu = document.querySelector('.component-header__menu');
	const header = document.querySelector('.component-header');
	const hero = document.querySelector('.component-hero');
	const heroReveal = hero ? hero.querySelector('.component-reveal') : null;

	body.dataset.loaded = true;

	// Delay hero copy transition only homepage on initial load
	if (window.location.pathname === '/') {
		// headerMenu.style.color = '#000000';
		header.style.opacity = 0;
		header.style.transition = 'opacity .75s ease';
		homePage.style.opacity = 0;
		homePage.style.transition = 'opacity .75s ease';
		if (heroReveal) {
			heroReveal.style.transitionDelay = '1.75s';
		}

		setTimeout(() => {
			// headerMenu.style.color = '#FFFFFF';
			header.style.opacity = 1;
			homePage.style.opacity = 1;
		}, 1000);
	}
};
