/* Helper functions */

/**
 * isExternalUrl - used to check for external links
*/

export const isExternalUrl = (url) => {
	let isExternal = false;

	if (url && (url.includes('//') || url.match(/((^(mailto|tel|sms|mms):)|www.)/) || url.includes('#'))) {
		isExternal = true;
	}

	return isExternal;
};

export default isExternalUrl;
